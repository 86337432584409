import React, { useEffect, useState } from "react";
import { NavbarPublic } from "../../components/NavbarPublic";
import ShareWishFooter from "../../components/ShareWishFooter";
import { useParams } from "react-router-dom";

function Referidos() {
  const [message, setMessage] = useState("");
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetch("https://www.giftoky.com/wp-json/v2/public/add/tokys/referred", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id:id }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setMessage("¡Felicidades! Ya has ganado 50 Tokys.");
          } else {
            setMessage("Hubo un error o ya has cobrado el premio.");
          }
        })
        .catch((error) => {
          console.error("Error al consumir el endpoint:", error);
          setMessage("Hubo un error al intentar cobrar el premio.");
        });
    }
  }, [id]);
  return (
    <>
      <NavbarPublic />
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}>
      {message ? (
          
        <div
          style={{
            padding: "20px",
            margin: "20px auto",
            maxWidth: "600px",
            textAlign: "center",
            backgroundColor: "#f0f0f0",
            fontSize: "1.5em",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
          >
          {message}
          </div>
      ): (
        
        <div
          style={{
            padding: "20px",
            margin: "20px auto",
            maxWidth: "600px",
            textAlign: "center",
            backgroundColor: "#f0f0f0",
            borderRadius: "10px",
            fontSize: "1.5em",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
          >
          Cobrando premio...
          </div>
      )}
      </div>
      <ShareWishFooter />
    </>
  );
}

export default Referidos;
